import React, { FC, ReactNode } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'hidden',
  // padding: '25px 10px',
  width: '100%',
  maxWidth: '600px',
  // height: '177px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '24px',
};

interface IProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

const ModalSimple: FC<IProps> = ({ open, onClose, children }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </>
  );
};

export default ModalSimple;
