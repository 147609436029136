import DOMPurify from 'dompurify';
import { AssignmentModel } from '../models';

export const cutString = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
};

export const isEmptyObject = (obj: object | null | undefined) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const filterBySectionName = (arr: AssignmentModel[]) => {
  let arrBySectionName: any[] = [];
  for (let idx = 0; idx < arr.length; idx++) {
    if (arr[idx].section) {
      let sectionId = arrBySectionName
        .map(object => object.sectionName?.id)
        .indexOf(arr[idx].section?.id);
      if (sectionId !== -1) {
        arrBySectionName[sectionId].sectionData.push(arr[idx]);
      } else {
        arrBySectionName.push({ sectionName: arr[idx].section, sectionData: [arr[idx]] });
      }
    } else {
      arrBySectionName.push(arr[idx]);
    }
  }
  return arrBySectionName;
};

export function toHoursAndMinutes(totalMinutes: number) {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function toHoursMinutesAndSeconds(seconds: number) {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
}

export const sanitizedHTML = (html: string | null) => {
  return DOMPurify.sanitize(html || '');
};

export const isEmptyField = (text = '') => {
  if (text && text !== '<p><br></p>') {
    return false;
  }
  return true;
};

export function removeDuplicates(arr: any[]) {
  return arr.reduce(function (acc, curr) {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);
}

export function getIOSVersion() {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const iOSDevice =
    /iPad|iPhone|iPod/.test(userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  if (iOSDevice) {
    const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
    if (version) {
      return version && version[1] ? version[1] : '';
    }
    const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (match) {
      return Number(match[1]);
    }
    return 20;
  }
  return null;
}

export const logEvent = (eventName: string, eventParams: any) => {
  // @ts-ignore
  if (window.gtag) {
    // @ts-ignore
    window.gtag('event', eventName, eventParams);
  } else {
    console.warn('gtag is not defined');
  }
};
