import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CalcCoursesModel,
  PaymentPayload,
  LoginModel,
  PaymentModel,
  SignUpModel,
  ProfileModel,
} from '../../models';
import api from '../../service/api';
import { AUTH_CLIENT_ID, AUTH_GRAND_TYPE } from '../../constants/api';
import { RootState } from '../store';
import { SimpleServerResponse } from '../../models/global';
import { setErrorStripe } from './userSlice';
import {
  ForgotPasswordPayload,
  SetForgotPasswordPayload,
  SignInPayload,
  SignUpPayload,
  UpdateProfilePayload,
} from './userRedux.model';
import { removeDuplicates } from '../../helpers/commonHelpers';

export const signIn = createAsyncThunk<LoginModel, SignInPayload>(
  'userSlice/signIn',
  async (form, { rejectWithValue }) => {
    try {
      return await api.post('auth/token/', {
        username: form.email,
        password: form.password,
        client_id: AUTH_CLIENT_ID,
        grant_type: AUTH_GRAND_TYPE,
      });
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  },
);

export const signUp = createAsyncThunk<SignUpModel, SignUpPayload, { state: RootState }>(
  'userSlice/signUp',
  async (payload, { dispatch, getState }) => {
    const {
      user: { signUp },
    } = getState();

    const lawIds: number[] = [];
    if (signUp?.options?.courses) {
      const selectedCourses = signUp?.options?.courses.filter(f =>
        signUp.step1Form.selectedCoursesIds.includes(f.id),
      );
      selectedCourses.map(course => {
        if (course.law_courses?.length) {
          course.law_courses.map(l => l.id);
          lawIds.push(...course.law_courses.map(l => l.id));
        }
      });
    }

    const data = {
      email: signUp.step2Form.email,
      email_confirm: signUp.step2Form.email,
      first_name: signUp.step2Form.first_name,
      last_name: signUp.step2Form.last_name,
      state: signUp.step2Form.state,
      password: signUp.step2Form.password,
      password_confirm: signUp.step2Form.password,
      courses: removeDuplicates([...signUp.step1Form.selectedCoursesIds, ...lawIds]),
      discountcode: payload.discountCode,
      payment_method: payload.stripeToken,
      search_state: signUp.step1Form.state,
      search_industry: signUp.step1Form.industry,
    };
    const response: any = await api.post('student/sign_up/', data);

    if (response.result === 'failed') {
      dispatch(setErrorStripe(response.msg));
      throw new Error();
    }
    return response;
  },
);

export const signOut = createAsyncThunk<void, void, { state: RootState }>(
  'userSlice/signOut',
  async (arg: void, { getState }) => {
    const state = getState();
    return await api.post('auth/logout/', {
      client_id: AUTH_CLIENT_ID,
      token: state.user.access_token,
    });
  },
);

export const fetchSignUpOptions = createAsyncThunk<PaymentModel, PaymentPayload>(
  'userSlice/fetchSignUpData',
  async ({ search_state, search_industry }) => {
    const response = await api.get('student/sign_up/', {
      params: { search_state, search_industry },
    });
    return response.data;
  },
);

export const calcSignUpCourses = createAsyncThunk<
  CalcCoursesModel,
  { ids: number[]; discountCode?: string; search_state: number; search_industry: number }
>(
  'userSlice/calcSignUpCourses',
  async ({ ids, discountCode = '', search_state, search_industry }) => {
    return await api.post('student/calculate/', {
      courses: ids,
      discountcode: discountCode,
      search_state: Number(search_state),
      search_industry: Number(search_industry),
    });
  },
);

export const checkEmail = createAsyncThunk(
  'userSlice/checkEmail',
  async (email: string) => {
    const response: any = await api.post('additional_func/check_unique_email/', {
      email: email.toLowerCase(),
    });
    if (response.result === 'failed') {
      throw new Error(response.msg);
    }
  },
);

export const forgotPassword = createAsyncThunk<
  SimpleServerResponse,
  ForgotPasswordPayload
>('userSlice/forgotPassword', async form => {
  return await api.post('student/forgot_password/', form);
});

export const setForgotPassword = createAsyncThunk<
  SimpleServerResponse,
  SetForgotPasswordPayload
>('userSlice/forgotPassword', async form => {
  return await api.post('student/set_forgot_password/', form);
});

export const getProfile = createAsyncThunk<ProfileModel>(
  'userSlice/getProfile',
  async () => await api.get('student/edit_profile/'),
);

export const updateProfile = createAsyncThunk<null, UpdateProfilePayload>(
  'userSlice/updateProfile',
  async data => await api.post('student/edit_profile/', data),
);
