import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import PopupPortal from '../../common/PopupPortal/PopupPortal';
import SVG from 'react-inlinesvg';
import Loader from '../../../assets/images/siteLogo/pas_loader_512x512.svg';

const BackdropSpinner = () => {
  return (
    <PopupPortal portalElementId={'Backdrop'}>
      <Backdrop
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0)',
        }}
        open={true}
      >
        <SVG src={Loader} width={128} height="100%" title="Loader" />
      </Backdrop>
    </PopupPortal>
  );
};

export default BackdropSpinner;
