import Router from './router/Router';
import { Helmet } from 'react-helmet';
import mainImg from './assets/images/auth/present_image_1.webp';
import multimediaImg from './assets/images/auth/present_image_2.webp';
import presentImage from './assets/images/auth/present_image_3.webp';
import logo from './assets/images/siteLogo/logo.svg';
import RemoteAlert from './components/common/AlertModal/RemoteAlert';

function App() {
  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={mainImg} />
        <link rel="preload" as="image" href={multimediaImg} />
        <link rel="preload" as="image" href={presentImage} />
        <link rel="preload" as="image" href={logo} />
      </Helmet>
      <RemoteAlert />
      <Router />
    </>
  );
}

export default App;
