const getDomainUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'https://v2dev.passmasters.com';
    case 'prod':
      return 'https://learn.passmasters.com';
    default:
      return 'https://v2dev.passmasters.com';
  }
};

const getWsUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'wss://v2dev.passmasters.com/ws';
    case 'prod':
      return 'wss://learn.passmasters.com/ws';
    default:
      return 'wss://v2dev.passmasters.com/ws';
  }
};

const getWpDomainUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'https://devwp.passmasters.com';
    case 'prod':
      return 'https://passmasters.com';
    default:
      return 'https://devwp.passmasters.com';
  }
};

const getStripeKey = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'pk_test_91SWseXZx9k0sYpQnAhRTJer';
    case 'prod':
      return 'pk_live_yYy012lMiRAYBOxILMvezcfO';
    default:
      return 'pk_test_91SWseXZx9k0sYpQnAhRTJer';
  }
};

export const STRIPE_PUBLIC_KEY = getStripeKey();

export const WEBSOCKET_URI = getWsUri();
export const DOMAIN_URI = getDomainUri();
export const WP_DOMAIN_URI = getWpDomainUri();
export const API_URI = `${DOMAIN_URI}/api/v1/`;

export const AUTH_CLIENT_ID = 'Jx2VynGyMX1ml3emX0nRz6xHIv71tQlygBLJustR';
export const AUTH_GRAND_TYPE = 'password';
